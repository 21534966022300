<template>
  <div>
    <div class="bg">
      <div class="dialogBox">
        <p class="title">登录</p>
        <el-form ref="form" :model="form" class="form" :rules="rules">
          <el-form-item label="账号" prop='username'>
            <el-input v-model="form.username" placeholder="请输入您的账号"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="pass">
            <el-input v-model="form.pass" placeholder="请输入您的密码" type="password"></el-input>
          </el-form-item>
        </el-form>
        <div class="btn" @click="login"> 登录</div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',

  data() {
    return {
      form: {
        username: '',
        pass: '',
      },
      rules: {
        username: [{ required: true, message: '请输入您的账号', trigger: 'blur' }],
        pass: [{ required: true, message: '请输入您的密码', trigger: 'blur' }],
      },
    }
  },

  mounted() {},

  methods: {
    login() {
      this.$message({
        message: '登录成功',
        type: 'success',
      })
      setTimeout(() => {
        this.$router.push({ path: '/' })
      })
      localStorage.setItem('userName', this.form.username)
      this.$bus.$emit('statusChange')
    },
  },
}
</script>

<style lang="less" scoped>
.bg {
  width: 100vw;
  height: 100vh;
  background: url('https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/banner-1.png') center center/cover no-repeat;

  .dialogBox {
    width: 650px;
    height: 450px;
    background: #ffffff;
    border-radius: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 42px 0;
    .title {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      text-align: center;
    }
    .form {
      margin: 0 auto;
      width: 300px;
      margin-top: 100px;
    }
    .btn {
      width: 150px;
      height: 30px;
      background: #5abec1;
      border-radius: 15px;
      text-align: center;
      line-height: 30px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      margin: 0 auto;
      margin-top: 144px;
      cursor: pointer;
    }
  }
}
</style>


<style lang='less'>
.bg {
  .el-form-item__label {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    margin-right: 14px;
  }
  .el-form-item__content {
    width: 210px;
    height: 30px;
    background: #f5f5f5;
    border-radius: 6px;
    display: inline-block;
    .el-input__inner {
      border: none;
      background: #f5f5f5;
    }
  }
}
</style>